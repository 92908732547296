import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  bookingInfo: {},
  participants: {},
};

export const bookingMaskSlice = createSlice({
  name: 'bookingMask',
  initialState,
  reducers: {
    initBooking: (_state, action) => {
      return action.payload;
    },
    updateBooking: (_state, action) => {
      const updatedBooking = {
        ..._state,
        ...action.payload,
      };
      return updatedBooking;
    },
    resetBooking: () => initialState,
  },
});

export const { initBooking, updateBooking, resetBooking } =
  bookingMaskSlice.actions;

export const bookingMaskSelector = (state) => state.bookingMask;

export default bookingMaskSlice.reducer;
