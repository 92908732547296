import { Text } from '@akin/ui-lib';
import { createStyles } from '@mantine/core';

const useStyles = createStyles(() => ({
  container: {
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#F6FAFB',
  },
}));

export default function ChatContainerBlank({ text }) {
  const { classes } = useStyles();
  return (
    <div className={classes.container}>
      <Text ta="center" color="dimmed" size="lg">
        {text}
      </Text>
    </div>
  );
}
