import { Menu } from '@mantine/core';

function MenuTarget(props) {
  return <Menu.Target {...props}>{props.children}</Menu.Target>;
}

function MenuItem(props) {
  return <Menu.Item {...props}>{props.children}</Menu.Item>;
}

function MenuLabel(props) {
  return <Menu.Label {...props}>{props.children}</Menu.Label>;
}

function MenuDropDown(props) {
  return <Menu.Dropdown {...props}>{props.children}</Menu.Dropdown>;
}

export default function MenuComponent(props) {
  return <Menu {...props}>{props.children}</Menu>;
}

MenuComponent.Target = MenuTarget;
MenuComponent.Item = MenuItem;
MenuComponent.Dropdown = MenuDropDown;
MenuComponent.Label = MenuLabel;
