let resolveCallback;
const EVENT_NAME = 'confirmModal';

function useConfirm() {
  const closeConfirm = () => {
    const event = new CustomEvent(EVENT_NAME, {
      detail: { open: false },
    });
    document.dispatchEvent(event);
  };

  const onConfirm = () => {
    closeConfirm();
    resolveCallback(true);
  };

  const onCancel = () => {
    closeConfirm();
    resolveCallback(false);
  };

  const confirm = (payload) => {
    const event = new CustomEvent(EVENT_NAME, {
      detail: {
        ...payload,
        open: true,
      },
    });
    document.dispatchEvent(event);
    return new Promise((res) => {
      resolveCallback = res;
    });
  };

  return { confirm, onConfirm, onCancel };
}

export default useConfirm;
