import { ActionIcon } from '@mantine/core';
import React from 'react';

export default React.forwardRef(function IconButton(
  { children, ...props },
  ref
) {
  return (
    <ActionIcon radius="xl" ml="md" ref={ref} {...props}>
      {children}
    </ActionIcon>
  );
});
