import React from 'react';
import { ActionIcon, createStyles } from '@mantine/core';
import { Text } from '@akin/ui-lib';
import CloseIcon from '@akin/ui-lib/svg/CrossIcon';
import ChatAvatar from './ChatAvatar';

const useStyles = createStyles((theme) => ({
  container: {
    position: 'relative',
    width: '25%',
    overflowY: 'auto',
    overflowX: 'hidden',
    paddingBottom: 20,
    ...theme.other.hideScrollBar,
    [theme.fn.smallerThan('xs')]: {
      display: 'none',
    },
    '&.toDisplay': {
      [theme.fn.smallerThan('xs')]: {
        display: 'unset',
        width: '100%',
        padding: '0 20px',
      },
    },
  },
  block: {
    backgroundColor: '#F6FAFB',
    padding: 8,
    borderRadius: 8,
    position: 'relative',
    '&.space': {
      padding: 15,
    },
  },
  halfWidth: {
    flex: 1,
  },
  flexBoxContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    gap: 5,
  },
  flexBox: {
    display: 'flex',
    alignItems: 'flex-start',
    gap: 15,
    marginTop: 5,
    '& div:first-of-type': {
      width: '25%',
    },
    '&.equalWidthChildren': {
      '& div:first-of-type': {
        width: '150px!important',
      },
    },
  },
  infoValue: {
    flex: '1 0 0',
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  actionIcon: {
    marginRight: 10,
  },
  closeContainer: {
    position: 'absolute',
    top: 10,
    right: 15,
  },
  closeIcon: {
    cursor: 'pointer',
    [theme.fn.largerThan('xs')]: {
      display: 'none',
    },
  },
}));

function InfoSidebarContent({ children, onClose }) {
  const { cx, classes } = useStyles();
  return (
    <div className={cx(classes.block, { space: true })}>
      <div className={classes.closeContainer}>
        <CloseIcon
          width={12}
          height={12}
          onClick={onClose}
          className={classes.closeIcon}
        />
      </div>
      {children}
    </div>
  );
}

function InfoSidebarSummary({ children }) {
  const { classes } = useStyles();
  return (
    <div className={classes.block}>
      <Text size="xs">{children}</Text>
    </div>
  );
}
function InfoSidebarTag({ children }) {
  const { classes } = useStyles();
  return (
    <div className={classes.block}>
      <Text size="xs">{children}</Text>
    </div>
  );
}
function InfoSidebarRating({ value, description }) {
  const { cx, classes } = useStyles();
  return (
    <div className={cx(classes.block, classes.halfWidth)}>
      <Text size="sm">{value}</Text>
      <Text size="xs">{description}</Text>
    </div>
  );
}

function InfoSidebarTitle({ title, icon, onClick }) {
  const { classes } = useStyles();
  return (
    <div className={classes.titleContainer}>
      <Text weight={600} mt={15} mb={10} size="sm">
        {title}
      </Text>
      {icon && (
        <ActionIcon onClick={onClick} className={classes.actionIcon}>
          {icon}
        </ActionIcon>
      )}
    </div>
  );
}

function InfoSidebarKeyValue({ label, value, equal }) {
  const { cx, classes } = useStyles();
  return (
    <div className={cx(classes.flexBox, { equalWidthChildren: equal })}>
      <Text color="dimmed" className={classes.infoLabel} size="xs">
        {label}
      </Text>
      <Text size="xs" className={classes.infoValue}>
        {value}
      </Text>
    </div>
  );
}

function InfoSidebarFlexBox(props) {
  const { children } = props;
  const { classes } = useStyles();
  return <div className={classes.flexBoxContainer}>{children}</div>;
}

function InfoSidebar(props) {
  const { children, toDisplay } = props;
  const { cx, classes } = useStyles();
  return <div className={cx(classes.container, { toDisplay })}>{children}</div>;
}

InfoSidebar.Content = InfoSidebarContent;
InfoSidebar.KeyValue = InfoSidebarKeyValue;
InfoSidebar.Title = InfoSidebarTitle;
InfoSidebar.Summary = InfoSidebarSummary;
InfoSidebar.FlexBox = InfoSidebarFlexBox;
InfoSidebar.Tag = InfoSidebarTag;
InfoSidebar.Rating = InfoSidebarRating;

export default InfoSidebar;
