import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isSuperAdmin: false,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    addUser: (_state, action) => action.payload,
    updateUser: (_state, action) => action.payload,
    resetUser: () => initialState,
  },
});

export const { addUser, updateUser, resetUser } = userSlice.actions;

export const userInfoSelector = (state) => state?.user;

export const spacePermissionSelector = (spaceId) => (state) =>
  state?.user?.permissions?.[spaceId];

export default userSlice.reducer;
