export default function APIError(error) {
  const defaultMsg = 'Unable to process your request';

  if (error && error?.response) {
    const errorObj = error.response;
    this.status = errorObj.status;
    this.statusText = errorObj.statusText;

    if (this.status === 0 && error.code === 'ERR_NETWORK') {
      this.message = 'Unable to connect to internet';
      return;
    }

    this.message = errorObj?.data?.message || defaultMsg;
  } else if (error?.code === 'ERR_CANCELED') {
    this.status = 455; // custom status code;
    this.message = 'Request cancelled by client';
  } else {
    this.status = 500;
    this.message = defaultMsg;
  }
}
