import { v4 as uuidv4 } from 'uuid';

export function debounce(func, timeout) {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, timeout);
  };
}

function traverseAndFlatten(currentNode, target, flattenedKey) {
  // eslint-disable-next-line no-restricted-syntax
  for (const key in currentNode) {
    // eslint-disable-next-line no-prototype-builtins
    if (currentNode.hasOwnProperty(key)) {
      let newKey;
      if (flattenedKey === undefined) {
        newKey = key;
      } else {
        newKey = `${flattenedKey}.${key}`;
      }

      const value = currentNode[key];
      if (typeof value === 'object') {
        traverseAndFlatten(value, target, newKey);
      } else {
        // eslint-disable-next-line no-param-reassign
        target[newKey] = value;
      }
    }
  }
}

export function flatten(obj) {
  const flattenedObject = {};
  traverseAndFlatten(obj, flattenedObject);
  return flattenedObject;
}

export function isEmptyObject(obj) {
  const flattenedObject = flatten(obj);
  return !Object.values(flattenedObject).some(
    (x) => x !== null && x !== '' && x !== undefined && x !== 0
  );
}

// Function to get language name from language code
export function getLanguageName(languageCode) {
  try {
    const browserLanguage =
      localStorage.getItem('LOCALE') || navigator.language || 'en';
    const displayNames = new Intl.DisplayNames([browserLanguage], {
      type: 'language',
    });
    return displayNames.of(languageCode);
  } catch (error) {
    return languageCode;
  }
}

export const generateUniqueId = () => uuidv4();
