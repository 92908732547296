import { useDispatch, useSelector } from 'react-redux';
import {
  searchSelector,
  openSearch as openSearchAction,
  closeSearch as closeSearchAction,
  selectItem as selectItemAction,
  resetSearch as resetSearchAction,
} from './searchSplice';

export default function useSearch() {
  const dispatch = useDispatch();
  const { open, selectedItem } = useSelector(searchSelector);

  const openSearch = () => {
    dispatch(openSearchAction());
  };

  const closeSearch = () => {
    dispatch(closeSearchAction());
  };

  const selectSearchItem = (data) => {
    dispatch(selectItemAction(data));
  };

  const resetSearch = () => {
    dispatch(resetSearchAction());
  };

  return {
    isSearchOpen: open,
    openSearch,
    closeSearch,
    selectSearchItem,
    resetSearch,
    selectedSearchItem: selectedItem,
  };
}
