import { Button, Title, Text } from '../../atomic';
import Modal from '../modal';

export default function CancelSessionModal({
  cancelTitleText,
  onClose,
  isOpen,
  onConfirm,
  cancelText,
  cancelButtonText,
}) {
  return (
    <Modal
      opened={isOpen}
      onClose={onClose}
      disableMobileFullScreen
      title={<Title order={4}>{cancelTitleText}</Title>}
    >
      <Text mb="xl">{cancelText}</Text>
      <Button color="red" onClick={onConfirm}>
        {cancelButtonText}
      </Button>
    </Modal>
  );
}
