import { createStyles } from '@mantine/core';
import { Title, Text } from '../../atomic';
import {
  MOBILE_HEADER_HEIGHT,
  FOOTER_HEIGHT,
  PROGRESS_BAR_HEIGHT,
} from './constants';

const useStyles = createStyles((theme) => ({
  root: {
    height: `calc(100vh - ${FOOTER_HEIGHT + PROGRESS_BAR_HEIGHT}px)`,
    padding: '0px 100px',
    overflowY: 'auto',
    [theme.fn.smallerThan('xs')]: {
      padding: '0px 20px',
    },
  },
  content: {
    maxWidth: 550,
  },
  mainTitle: {
    marginTop: 40,
    marginBottom: theme.spacing.xl,
    [theme.fn.smallerThan('xs')]: {
      marginTop: 40 + MOBILE_HEADER_HEIGHT,
    },
  },
}));

function MainTitle(props) {
  const { classes } = useStyles();
  return (
    <Title order={4} className={classes.mainTitle} {...props}>
      {props.children}
    </Title>
  );
}

function SubTitle(props) {
  return (
    <Text size="sm" {...props}>
      {props.children}
    </Text>
  );
}

export default function MainContent({ className, children }) {
  const { classes, cx } = useStyles();
  return (
    <div className={cx(classes.root, className)}>
      <div className={classes.content}>{children}</div>
    </div>
  );
}

MainContent.Title = MainTitle;
MainContent.SubTitle = SubTitle;
