import intersection from 'lodash/intersection';
import PAGE_ROUTES from '../../constants/pageRoutes';
import { MODULES, MODULE_PERMISSIONS } from './constants';

const FRONTDESK_USER_PERMISSIONS = [
  'INBOX::CHANNEL:ALL',
  'INBOX::CHANNEL:WHATSAPP',
];
const MANAGER_UER_PERMISSIONS = [
  'HOMEPAGE::ALL',
  'INBOX::CHANNEL:ALL',
  'INBOX::CHANNEL:WHATSAPP',
];

// eslint-disable-next-line import/prefer-default-export
export function getLandingPageRoute({ currentSpaceId, userPermissions }) {
  const userSpacePermissions = userPermissions?.[currentSpaceId];

  const hasHomePagePermissions = intersection(
    userSpacePermissions,
    MODULE_PERMISSIONS[MODULES.home]
  );

  if (hasHomePagePermissions?.length > 0) {
    return PAGE_ROUTES.home;
  }

  const hasInboxPagePermissions = intersection(
    userSpacePermissions,
    MODULE_PERMISSIONS[MODULES.inbox]
  );

  if (hasInboxPagePermissions?.length > 0) {
    return PAGE_ROUTES.inbox;
  }

  return PAGE_ROUTES.home;
}

export function getUserRole(userSpacePermissions) {
  if (!userSpacePermissions) return;

  const hasAccessToAllPages = userSpacePermissions?.includes(
    'CHANGE_SPACE_DETAILS'
  );

  if (hasAccessToAllPages) return 'admin';

  const isFrontdeskRole = userSpacePermissions.every((permission) =>
    FRONTDESK_USER_PERMISSIONS.includes(permission)
  );

  if (isFrontdeskRole) return 'frontdesk';

  const isMangerRole = userSpacePermissions.every((permission) =>
    MANAGER_UER_PERMISSIONS.includes(permission)
  );

  if (isMangerRole) return 'manager';
}
