const MOBILE_HEADER_HEIGHT = 50;
const FOOTER_HEIGHT = 50;
const PROGRESS_BAR_HEIGHT = 3;
const SIDEBAR_FOOTER_HEIGHT = 54;

export {
  MOBILE_HEADER_HEIGHT,
  FOOTER_HEIGHT,
  PROGRESS_BAR_HEIGHT,
  SIDEBAR_FOOTER_HEIGHT,
};
