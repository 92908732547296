import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

export const getURLparams = () => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);

  const query = {};

  // eslint-disable-next-line no-restricted-syntax
  for (const [key, value] of urlParams) {
    query[key] = value;
  }

  return query;
};

export default function useURLParams() {
  const router = useRouter();
  const [params, setParams] = useState(null);

  useEffect(() => {
    const query = getURLparams();
    setParams({ ...query });
  }, []);

  const setURLParams = (data) => {
    const query = router?.query || getURLparams();
    const updatedParams = { ...query, ...data };
    router.replace(
      {
        pathname: router.pathname,
        query: { ...updatedParams },
      },
      undefined,
      { shallow: true }
    );
  };

  const routeWithReturnURL = (path, query) => {
    const returnUrl = encodeURIComponent(router.asPath);
    const queryParams = { ...query, return_url: returnUrl };
    router.push({
      pathname: path,
      query: { ...queryParams },
    });
  };

  const routeToReturnURL = (defaultRoute) => {
    const query = params || getURLparams();

    if (query.return_url) {
      const decodedURL = decodeURIComponent(query.return_url);
      router.replace(decodedURL);
      return;
    }

    router.replace(defaultRoute);
  };

  const updateURLParams = ({ removeParams = [] }) => {
    const query = router?.query || getURLparams();
    const { pathname } = router;

    if (removeParams.length > 0) {
      removeParams.forEach((param) => {
        delete query[param];
      });
    }

    router.replace({ pathname, query }, undefined, { shallow: true });
  };

  return {
    params,
    getURLparams,
    setURLParams,
    routeWithReturnURL,
    routeToReturnURL,
    updateURLParams,
    router,
  };
}
