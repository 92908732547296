import { createStyles, Textarea, UnstyledButton } from '@mantine/core';
import { useRef, useState } from 'react';

const useStyles = createStyles(() => ({
  root: {
    display: 'flex',
    backgroundColor: '#F6FAFB',
    padding: 10,
    alignItems: 'center',
    gap: 5,
  },
  container: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#FFFFFF',
    padding: '0 10px',
    border: '1px solid #EEF1F0',
    borderRadius: 8,
    '& .mantine-Textarea-root': {
      flex: 1,
    },
  },
  textArea: {
    '& textarea': {
      padding: 10,
    },
  },
}));

const PaperPlaneIcon = ({ width, height, fill }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
      width={width}
      height={height}
      fill={fill}
    >
      <path d="M511.6 36.86l-64 415.1c-1.5 9.734-7.375 18.22-15.97 23.05c-4.844 2.719-10.27 4.097-15.68 4.097c-4.188 0-8.319-.8154-12.29-2.472l-122.6-51.1l-50.86 76.29C226.3 508.5 219.8 512 212.8 512C201.3 512 192 502.7 192 491.2v-96.18c0-7.115 2.372-14.03 6.742-19.64L416 96l-293.7 264.3L19.69 317.5C8.438 312.8 .8125 302.2 .0625 289.1s5.469-23.72 16.06-29.77l448-255.1c10.69-6.109 23.88-5.547 34 1.406S513.5 24.72 511.6 36.86z" />
    </svg>
  );
};

export default function MessageInput({
  onSend,
  className,
  disabled = false,
  placeholder = 'Type message here',
  clearOnSend = true,
  children,
}) {
  const { cx, classes } = useStyles();
  const [disableSendBtn, setDisableSendBtn] = useState(true);
  const ref = useRef();

  const sendHandler = () => {
    const value = ref.current.value;
    if (value.trim()) {
      onSend(ref.current.value);
    }
    if (!clearOnSend) return;
    ref.current.value = '';
    setDisableSendBtn(true);
  };

  const keyPressHandler = (e) => {
    if (e.charCode === 13 && !e.shiftKey) {
      e.preventDefault();
      sendHandler();
    }
  };

  const onChangeHandler = (e) => {
    const value = e.target.value;
    setDisableSendBtn(!value);
  };

  return (
    <div className={cx(classes.root, className)}>
      {children}
      <div className={classes.container}>
        <Textarea
          ref={ref}
          className={classes.textArea}
          placeholder={placeholder}
          variant="unstyled"
          minRows={1}
          maxRows={4}
          autosize
          disabled={disabled}
          onKeyPress={keyPressHandler}
          onChange={onChangeHandler}
        />
        <UnstyledButton disabled={disableSendBtn} onClick={sendHandler}>
          <PaperPlaneIcon
            width={20}
            height={20}
            fill={disableSendBtn ? '#dedede' : '#636ecb'}
          />
        </UnstyledButton>
      </div>
    </div>
  );
}
