import { createStyles } from '@mantine/core';

const useStyles = createStyles((theme) => ({
  root: {
    flex: 1,
    overflowY: 'scroll',
    overflowX: 'hidden',
    padding: '0 20px',
    backgroundColor: '#F6FAFB',
    ...theme.other.hideScrollBar,
  },
}));

export default function MessageList({ children, className }) {
  const { cx, classes } = useStyles();
  return <div className={cx(classes.root, className)}>{children}</div>;
}
