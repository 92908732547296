import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isSpaceLoading: false,
  currentSpaceId: null,
  listingCount: 0,
  stripe: {},
  cryptoPayout: {},
  razorpayAccount: {},
  bookingEngineConfig: {},
  bookingsCount: 0,
  isProfileUpdated: false,
  bookingSources: {},
  spaceProfile: {},
  paymentMethods: {},
  listingGroups: {},
  monthlyMessagesCount: 0,
  remainingMessages: 0,
};

export const spaceSettingSlice = createSlice({
  name: 'spaceSettings',
  initialState,
  reducers: {
    updateSpaceSettings: (_state, action) => {
      const updatedSettings = {
        ..._state,
        ...action.payload,
      };
      return updatedSettings;
    },
    setCurrentSpaceId: (_state, action) => {
      _state.currentSpaceId = action.payload;
    },
    resetSpaceSettings: () => initialState,
  },
});

export const { updateSpaceSettings, setCurrentSpaceId, resetSpaceSettings } =
  spaceSettingSlice.actions;

export const spaceSettingsSelector = (state) => state.spaceSettings;

export const currentSpaceIdSelector = (state) =>
  state.spaceSettings.currentSpaceId;

export const spaceProfileSelector = (state) => state.spaceSettings.spaceProfile;

export const isSpaceLoadingSelector = (state) =>
  state.spaceSettings.isSpaceLoading;

export default spaceSettingSlice.reducer;
