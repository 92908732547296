// eslint-disable-next-line import/no-extraneous-dependencies
import { i18n } from '@lingui/core';
import { I18nProvider } from '@lingui/react';
import { useEffect } from 'react';
import { en, de, es, it } from 'make-plural/plurals';
import { messages as enMessages } from '../locales/en/messages';

export const DEFAULT_LOCALE = 'en';

i18n.loadLocaleData({
  en: { plurals: en },
  de: { plurals: de },
  es: { plurals: es },
  it: { plurals: it },
});

i18n.load(DEFAULT_LOCALE, enMessages);
i18n.activate(DEFAULT_LOCALE);

async function getTranslation(locale, isProduction) {
  // Loading compiled message files for production...
  if (isProduction) {
    const data = await import(`../locales/${locale}/messages.js`);
    return data;
  }

  // Loading po message files for development...
  const data = await import(`@lingui/loader!../locales/${locale}/messages.po`);
  return data;
}

export async function dynamicActivateLanguage(locale = DEFAULT_LOCALE) {
  const { messages } = await getTranslation(
    locale,
    process.env.NODE_ENV === 'production'
  );
  i18n.load(locale, messages);
  i18n.activate(locale);
  localStorage.setItem('LOCALE', locale);
}

export function LanguageProvider({ children }) {
  useEffect(() => {
    // With this method we dynamically load the catalogs
    const savedLocale = localStorage.getItem('LOCALE');
    // eslint-disable-next-line no-underscore-dangle
    if (I18nProvider._locale !== savedLocale)
      if (savedLocale) dynamicActivateLanguage(savedLocale);
  }, []);

  return (
    <I18nProvider i18n={i18n} forceRenderOnLocaleChange>
      {children}
    </I18nProvider>
  );
}
