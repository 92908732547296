import {
  createStyles,
  ActionIcon,
  Image,
  Text,
  useMantineColorScheme,
} from '@mantine/core';
import ICONS from '@akin/core-lib/icons';

const useStyles = createStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    padding: 10,
    [theme.fn.smallerThan('sm')]: {
      padding: 'unset',
    },
  },
  textContainer: {
    width: 60,
    display: 'flex',
    justifyContent: 'center',
  },
  icon: {
    borderRadius: '50%',
    border: '1px solid #666',
    opacity: 0.4,
    '&:hover': {
      opacity: 0.8,
      border: '1px solid #333',
    },
    '&.inactive': {
      cursor: 'unset',
    },
    '&:disabled': {
      opacity: 0.2,
      border: '1px solid #BDBDBD',
      cursor: 'not-allowed',
    },
    '&.darkBackground': {
      border: '1px solid #fff',
    },
  },
}));

export default function PlusMinus({
  count,
  min,
  disableMax = false,
  handleIncrement,
  handleDecrement,
}) {
  const { classes, cx } = useStyles();
  const { colorScheme } = useMantineColorScheme();
  const dark = colorScheme === 'dark';
  return (
    <div className={classes.root}>
      <ActionIcon
        size={35}
        disabled={count === min}
        variant="transparent"
        className={cx(classes.icon, { darkBackground: dark })}
        onClick={handleDecrement}
      >
        <Image
          height={10}
          width={10}
          src={dark ? ICONS.minusWhite : ICONS.minus}
        />
      </ActionIcon>
      <div className={classes.textContainer}>
        <Text size={18} color={dark ? 'gray.2' : 'gray.8'}>
          {count ? count : 0}
        </Text>
      </div>
      <ActionIcon
        size={35}
        disabled={disableMax}
        variant="transparent"
        className={cx(classes.icon, { darkBackground: dark })}
        onClick={handleIncrement}
      >
        <Image
          height={10}
          width={10}
          src={dark ? ICONS.plusWhite : ICONS.plus2}
        />
      </ActionIcon>
    </div>
  );
}
