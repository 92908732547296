import { Spotlight, Image, Loader, Text } from '@akin/ui-lib';
import { Autocomplete } from '@mantine/core';
import { t } from '@lingui/macro';
import ICONS from '@akin/core-lib/icons';
import { useState, forwardRef, useCallback } from 'react';
import useSearch from './useSearch';
import { globalSearch } from '../../services/search';
import { showError } from '../../lib/notifications';
import { debounce } from '../../utils';
import useSpace from '../../hooks/useSpace';

function formatSearchData(data = []) {
  const formattedData = data.map((item, index) => ({
    value: `${index}_${item.listingId}`,
    bookerName: item.bookerName,
    bookingDetails: { ...item },
  }));
  return formattedData;
}

const AutoCompleteItem = forwardRef((props, ref) => {
  const { value, bookerName, bookingDetails, ...others } = props;
  return (
    <div ref={ref} {...others}>
      <Text>{bookerName}</Text>
      <Text size="sm" color="dimmed">
        {bookingDetails.listingTitle}
      </Text>
    </div>
  );
});

export default function Search() {
  const { isSearchOpen, closeSearch, selectSearchItem } = useSearch();
  const { currentSpaceId } = useSpace();
  const [searchList, setSearchList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const searchHandler = async (value) => {
    setIsLoading(true);
    try {
      const { searchResult } = await globalSearch({ keyword: value });
      const formattedResult = formatSearchData(searchResult);
      setSearchList([...formattedResult]);
    } catch (error) {
      showError(error.message);
    }
    setIsLoading(false);
  };

  const debouncedChangeHandler = useCallback(debounce(searchHandler, 750), []);

  const onChangeHandler = async (value) => {
    if (currentSpaceId) debouncedChangeHandler(value);
  };

  const onSelectHandler = (item) => {
    const {
      bookingDetails: {
        listingTitle,
        locationDetail,
        start,
        end,
        amount,
        bookingCount,
        maxCapacity,
        listingDuration,
        listingCode,
        instanceDateTime,
        prefix,
        listingCoverImage,
      },
    } = item;

    const payload = {
      listingTitle,
      locationDetail,
      startDate: new Date(start),
      endDate: new Date(end),
      amount,
      bookingCount,
      maxCapacity,
      listingDuration,
      listingCode,
      instanceDateTime,
      prefix,
      listingCoverImage,
    };
    selectSearchItem(payload);
    closeSearch();
  };

  const onCloseHandler = () => {
    setSearchList([]);
    closeSearch();
  };

  return (
    isSearchOpen && (
      <Spotlight onOverlayClick={onCloseHandler}>
        <Autocomplete
          placeholder={t`Search`}
          icon={<Image width={20} height={20} src={ICONS.search} />}
          data={searchList}
          onItemSubmit={onSelectHandler}
          rightSection={isLoading && <Loader size="xs" />}
          rightSectionWidth={50}
          onChange={onChangeHandler}
          size="lg"
          radius="xl"
          nothingFound={t`No results found`}
          limit={10}
          maxDropdownHeight={400}
          itemComponent={AutoCompleteItem}
          filter={(value, item) =>
            item.bookerName.toLowerCase().includes(value.toLowerCase().trim())
          }
        />
      </Spotlight>
    )
  );
}
