import axios from 'axios';
import { store } from '../store';

const http = axios.create({
  baseURL: process.env.NEXT_PUBLIC_API_BASE_URL,
});

http.interceptors.request.use(
  (config) => {
    const { spaceSettings } = store.getState();

    if (spaceSettings?.currentSpaceId) {
      config.headers.space_id = spaceSettings.currentSpaceId;
    }

    return config;
  },
  (error) => Promise.reject(error)
);

http.interceptors.response.use(
  (response) => response,
  (error) => {
    if (typeof window !== 'undefined' && error.response?.status === 401) {
      const event = new Event('logoutUser');
      document.dispatchEvent(event);
    }
    return Promise.reject(error);
  }
);

export default http;
