import { useDispatch, useSelector } from 'react-redux';
import {
  currentSpaceIdSelector,
  resetSpaceSettings,
} from '../features/spaces/spaceSettingSlice';
import {
  spaceSelector,
  addNewSpace,
  updateSpace,
  resetSpaces as resetSpacesAction,
  spacesSelector,
  removeSpace as removeSpaceAction,
} from '../features/spaces/spacesSlice';

export default function useSpace() {
  const dispatch = useDispatch();
  const currentSpaceId = useSelector(currentSpaceIdSelector);
  const currentSpace = useSelector(spaceSelector(currentSpaceId));
  const spaces = useSelector(spacesSelector);

  const createNewSpace = (payload) => {
    dispatch(addNewSpace(payload));
  };

  const updateCurrentSpace = (payload) => {
    dispatch(updateSpace(payload));
  };

  const deleteCurrentSpace = () => {};

  const removeSpace = (payload) => {
    dispatch(removeSpaceAction(payload));
  };

  const resetSpaces = () => {
    dispatch(resetSpacesAction());
    dispatch(resetSpaceSettings());
  };

  return {
    currentSpaceId,
    currentSpace,
    removeSpace,
    updateCurrentSpace,
    deleteCurrentSpace,
    createNewSpace,
    resetSpaces,
    spaces,
  };
}
