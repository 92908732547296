import { createStyles } from '@mantine/core';
import { Text, Title } from '../../atomic';
import { SIDEBAR_FOOTER_HEIGHT } from './constants';

const useStyles = createStyles((theme) => ({
  root: {},
  titleContainer: {
    minHeight: 100,
    margin: '0px 10px 0 20px',
    paddingTop: 40,
  },

  sidebarItem: {
    border: '2px solid transparent',
    marginBottom: theme.spacing.md,
    padding: 6,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    cursor: 'pointer',
    '&.isActive': {
      border: '2px solid',
      borderColor: theme.black,
      borderRadius: 20,
    },
  },
  checkIcon: {
    border: '1px solid',
    borderRadius: '50%',
    width: 15,
    height: 15,
    '&.isCompleted': {
      backgroundColor: theme.black,
      backgroundImage: `url("data:image/svg+xml,%3Csvg width='32' height='32' viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.414 11L4 12.414l5.414 5.414L20.828 6.414 19.414 5l-10 10z' fill='%23fff' fill-rule='nonzero'/%3E%3C/svg%3E ")`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 19,
      backgroundPosition: '0px 1px',
    },
  },
  navBody: { padding: 10 },
  footer: {
    padding: 10,
    height: SIDEBAR_FOOTER_HEIGHT,
    position: 'absolute',
    bottom: 0,
    right: 0,
    left: 0,
  },
}));

function SidebarTitle({ title }) {
  const { classes } = useStyles();
  return (
    <div className={classes.titleContainer}>
      <Title order={4} weight={500}>
        {title}
      </Title>
    </div>
  );
}

function SidebarBody({ children }) {
  const { classes } = useStyles();
  return <div className={classes.navBody}>{children}</div>;
}

function SidebarItem({ title, isActive, isCompleted, onClick }) {
  const { classes, cx } = useStyles();
  return (
    <div className={cx(classes.sidebarItem, { isActive })} onClick={onClick}>
      <Text size="sm" pl={5}>
        {title}
      </Text>
      <div className={cx(classes.checkIcon, { isCompleted })} />
    </div>
  );
}

function SidebarFooter({ children, className }) {
  const { classes, cx } = useStyles();
  return <div className={cx(classes.footer, className)}>{children}</div>;
}

export default function Sidebar({ children, className }) {
  const { classes, cx } = useStyles();
  return <div className={cx(classes.root, className)}>{children}</div>;
}

Sidebar.Title = SidebarTitle;
Sidebar.Item = SidebarItem;
Sidebar.Body = SidebarBody;
Sidebar.Footer = SidebarFooter;
