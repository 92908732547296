import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  currentRoomTypeId: null,
  currentAddonId: null,
  stayProvider: null,
  allAmenities: [],
  allSleepingArrangements: [],
  allStayProviderCategories: [],
  roomTypes: {},
  addons: {},
};

export const staySetupSlice = createSlice({
  name: 'staySetup',
  initialState,
  reducers: {
    initializeStaySetup: (_state, action) => {
      const staySetupData = {
        ..._state,
        ...action.payload,
      };
      return staySetupData;
    },
    updateStaySetup: (_state, action) => {
      const staySetupData = {
        ..._state,
        ...action.payload,
      };
      return staySetupData;
    },
    updateStayProvider: (_state, action) => {
      const updatedStayProvider = {
        ..._state.stayProvider,
        ...action.payload,
      };
      return updatedStayProvider;
    },

    updateRoomType: (_state, action) => {
      _state.roomTypes[_state.currentRoomTypeId] = action.payload;
    },
    setCurrentRoomTypeId: (_state, action) => {
      _state.currentRoomTypeId = action.payload;
    },

    updateAddon: (_state, action) => {
      _state.addons[_state.currentAddonId] = action.payload;
    },
    setCurrentAddonId: (_state, action) => {
      _state.currentAddonId = action.payload;
    },
    updateRatePlan: (_state, action) => {
      _state.ratePlans[_state.currentRatePlanId] = action.payload;
    },
    setCurrentRatePlanId: (_state, action) => {
      _state.currentRatePlanId = action.payload;
    },

    resetStaySetup: () => initialState,
  },
});

export const {
  initializeStaySetup,
  updateStaySetup,
  updateStayProvider,
  setCurrentRoomTypeId,
  setCurrentAddonId,
  setCurrentRatePlanId,
  resetStaySetup,
  updateRoomType,
  updateAddon,
  updateRatePlan,
} = staySetupSlice.actions;

export const staySetupSelector = (state) => state.staySetup;

export const currentRoomTypeIdSelector = (state) =>
  state.staySetup.currentRoomTypeId;

export const currentAddonIdSelector = (state) => state.staySetup.currentAddonId;

export default staySetupSlice.reducer;
