import { Switch, createStyles } from '@mantine/core';

const useStyles = createStyles((theme) => ({
  root: {
    flexDirection: 'row-reverse',
    justifyContent: 'space-between',
    '& .mantine-Switch-label': {
      padding: 0,
    },
    '& .mantine-Switch-input': {
      backgroundColor: theme.colors.gray[8],
      '&:checked': {
        backgroundColor: theme.colors.blue,
      },
    },
  },
}));

export default function SwitchComponent(props) {
  const { classes } = useStyles();
  return <Switch className={classes.root} {...props} />;
}
