import { useDispatch, useSelector } from 'react-redux';
import { normalizeArray } from '@akin/core-lib/utils/dataTransform';
import { useRouter } from 'next/router';
import { getPlatformContent } from '../services/space';
import {
  spaceSettingsSelector,
  updateSpaceSettings as updateSettingsAction,
  setCurrentSpaceId,
} from '../features/spaces/spaceSettingSlice';
import useSpace from './useSpace';
import useUser from './useUser';
import { dynamicActivateLanguage } from '../lib/LanguageProvider';
import { showError } from '../lib/notifications';
import { spacePermissionSelector } from '../features/userProfile/userSlice';

function processCryptoAddresses(cryptoPayoutAddresses = []) {
  let selectedAddressId = '';

  const normalizedCryptoAddresses = cryptoPayoutAddresses.reduce(
    (prev, curr) => {
      if (curr.is_default) {
        selectedAddressId = curr.crypto_payout_address_id;
      }

      // eslint-disable-next-line no-param-reassign
      prev[curr.crypto_payout_address_id] = { ...curr };
      return prev;
    },
    {}
  );

  return { selectedAddressId, normalizedCryptoAddresses };
}

export default function useSpaceSettings() {
  const dispatch = useDispatch();
  const router = useRouter();
  const { user } = useUser();
  const { currentSpace, createNewSpace, spaces } = useSpace();
  const spaceSettings = useSelector(spaceSettingsSelector);

  // user.permissions = { null: ['*'] }
  // when spaceId "null" exists in the permissions object with value ['*'] in user reducer,
  // the user is considered to be a "Super admin"
  const permissionSpaceId = user.isSuperAdmin ? null : currentSpace?.space_id;

  const userSpacePermissions = useSelector(
    spacePermissionSelector(permissionSpaceId)
  );

  const updateSpaceSettings = (payload) => {
    dispatch(updateSettingsAction(payload));
  };

  const getSpaceContent = async () => {
    if (!currentSpace?.space_id) return;

    updateSpaceSettings({ isSpaceLoading: true });
    try {
      const { data } = await getPlatformContent();

      // updating the space data or creating space data incase of corrupted/missing
      // space data in localStorage
      if (data.space) createNewSpace(data.space);

      // updateUser(data.user); //NOTE: using get-user API response to handle user data in reducer henceforth
      dynamicActivateLanguage(data?.user?.platform_language);

      const {
        searchListings,
        stripeAccountStatus,
        stripeApplePayCountries,
        bookingEngineConfig,
        searchBookings,
        stripePayoutCount,
        standardCountries,
        spaceProfile,
        cryptoPayoutAddresses,
        razorpayAccount,
        bookingSources,
        space,
        monthlyMessagesCount,
        remainingMessages,
        aiBookNowConfig,
        aiLeadGenConfig,
        configuredReportChannels,
        isGroup,
        isStandaloneProperty,
        subSpaces,
        spaceCategories,
      } = data;

      const { selectedAddressId, normalizedCryptoAddresses } =
        processCryptoAddresses(cryptoPayoutAddresses);

      const normalizedPaymentMethods = normalizeArray(
        space?.payment_methods || [],
        'payment_method_name'
      );

      const normalizedListingGroups = normalizeArray(
        space?.listing_groups || [],
        'listing_group_id'
      );

      const settings = {
        listingCount: searchListings?.pagination?.total,
        stripe: {
          ...stripeAccountStatus,
          payoutCount: stripePayoutCount,
          countries: standardCountries,
          applePayCountries: [...stripeApplePayCountries],
        },
        cryptoPayout: {
          selectedAddressId,
          addresses: { ...normalizedCryptoAddresses },
        },
        razorpayAccount: {
          maskedApiKey: razorpayAccount?.razorpay_key_id,
          maskedSecretKey: razorpayAccount?.razorpay_key_secret,
        },
        bookingEngineConfig,
        bookingsCount: searchBookings?.pagination?.total,
        isProfileUpdated: !!user.profile_pic_key,
        spaceProfile,
        bookingSources,
        paymentMethods: { ...normalizedPaymentMethods },
        listingGroups: { ...normalizedListingGroups },
        monthlyMessagesCount,
        remainingMessages,
        isSpaceLoading: false,
        aiBookNowConfig,
        aiLeadGenConfig,
        configuredReportChannels,
        isGroup: isGroup || false, // isGroup is true when subtenant of a space is *. Denotes a "Group property".
        isStandaloneProperty: isStandaloneProperty || false, // Properties which do not belong to any group.
        subSpaces: subSpaces && [...subSpaces],
        spaceCategories,
      };
      updateSpaceSettings(settings);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      showError(`Unable to load space: ${currentSpace.name}`);
    }
  };

  const switchToSpace = async (spaceId) => {
    try {
      dispatch(setCurrentSpaceId(spaceId));
      await getSpaceContent();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  };

  const loadSpaceBySpaceCode = async (spaceCode = '') => {
    try {
      if (!spaceCode) {
        router.push('/404');
        throw new Error(`Unable to find space ${spaceCode}`);
      }

      const spaceResult = Object.values(spaces).find(
        (space) => space.space_code === Number(spaceCode)
      );

      if (!spaceResult) {
        router.push('/404');
        throw new Error(`Unable to find space ${spaceCode}`);
      }

      await switchToSpace(spaceResult.space_id);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  };

  return {
    currentSpace,
    spaceSettings,
    getSpaceContent,
    updateSpaceSettings,
    switchToSpace,
    loadSpaceBySpaceCode,
    userSpacePermissions,
  };
}
