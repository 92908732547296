import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

export const collaborationSplice = createSlice({
  name: 'collaboration',
  initialState,
  reducers: {
    initCollaboration: (_state, action) => action.payload,
    updateCollaboration: (_state, action) => ({ ..._state, ...action.payload }),
    resetCollaboration: () => initialState,
  },
});

export const { initCollaboration, updateCollaboration, resetCollaboration } =
  collaborationSplice.actions;

export const collaborationSelector = (state) => state.collaboration;

export default collaborationSplice.reducer;
