import { MantineProvider, ColorSchemeProvider } from '@mantine/core';
import { theme, PlatformGlobalStyles } from '@akin/ui-lib';
import { useState } from 'react';
import THEME from '@akin/core-lib/constants/theme';

export default function MantineColorSchemeProvider({ children }) {
  const [colorScheme, setColorScheme] = useState(THEME.light.value);

  const toggleColorScheme = (value) =>
    setColorScheme(
      value ||
        (colorScheme === THEME.dark.value
          ? THEME.light.value
          : THEME.dark.value)
    );

  return (
    <ColorSchemeProvider
      colorScheme={colorScheme}
      toggleColorScheme={toggleColorScheme}
    >
      <MantineProvider withGlobalStyles withNormalizeCSS theme={theme}>
        <PlatformGlobalStyles />
        {children}
      </MantineProvider>
    </ColorSchemeProvider>
  );
}
