import { useEffect } from 'react';
import Head from 'next/head';
import { NotificationsProvider } from '@mantine/notifications';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import useAuth from '../hooks/useAuth';
import TopProgressBar from '../components/TopProgressBar';
import useShowPage from '../hooks/useShowPage';
import { store, persistor } from '../store';

import '@fullcalendar/common/main.css';
import '@fullcalendar/daygrid/main.css';
import '@fullcalendar/timegrid/main.css';
import useSpaceSettings from '../hooks/useSpaceSettings';
import Search from '../features/search';
import ConfirmDialog from '../components/common/ConfirmDialog';
import { LanguageProvider } from '../lib/LanguageProvider';
import { INVITE_PAGES } from '../constants/pageRoutes';
import MantineProvider from '../lib/MantineProvider';

function PlatformApp({ Component, pageProps }) {
  const { showPage, isLoggedIn, currentRoute, spaceCodeQuery, isRouterReady } =
    useShowPage();
  const auth = useAuth();
  const { getSpaceContent, loadSpaceBySpaceCode } = useSpaceSettings();

  useEffect(() => {
    // Auth listeners to trigger logout events............
    document.addEventListener('logoutUser', auth.logout);

    return () => {
      document.removeEventListener('logoutUser', auth.logout);
    };
  }, []);

  // useEffect BELOW FETCHES SPACE CONTENTS AFTER LOGIN / ON APP MOUNT...
  useEffect(() => {
    if (!isRouterReady) return;

    if (isLoggedIn) {
      // Avoid get space content call for invite flow use-cases.....
      if (INVITE_PAGES.includes(currentRoute)) return;

      // Check auth status for protected pages on App mount.........
      auth.checkAuthStatus();

      // Route which does not include spaceCode query..
      if (!spaceCodeQuery) getSpaceContent();

      // Route which includes spaceCode query..
      if (spaceCodeQuery) loadSpaceBySpaceCode(spaceCodeQuery);
    }
  }, [isLoggedIn, isRouterReady]);

  const getLayout = Component.getLayout || ((page) => page);
  const MainComponent = getLayout(<Component {...pageProps} />);

  return (
    <>
      <Head>
        <title>D3x</title>
        <meta
          name="viewport"
          content="minimum-scale=1, initial-scale=1, width=device-width, maximum-scale=1"
        />
      </Head>

      <TopProgressBar />

      <LanguageProvider>
        <MantineProvider>
          <NotificationsProvider>
            {showPage ? MainComponent : null}
          </NotificationsProvider>

          {/* ----------GLOBAL SEARCH------------- */}
          <Search />

          <ConfirmDialog />
        </MantineProvider>
      </LanguageProvider>
    </>
  );
}

function App(props) {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <PlatformApp {...props} />
      </PersistGate>
    </Provider>
  );
}

export default App;
