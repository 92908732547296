import { Text } from '@mantine/core';
import { forwardRef } from 'react';

// eslint-disable-next-line react/display-name
const TextComponent = forwardRef((props, ref) => {
  return (
    <Text {...props} ref={ref}>
      {props.children}
    </Text>
  );
});

export default TextComponent;
