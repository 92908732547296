import { Title } from '@mantine/core';
import { forwardRef } from 'react';

// eslint-disable-next-line react/display-name
const TitleComponent = forwardRef((props, ref) => {
  return (
    <Title {...props} ref={ref}>
      {props.children}
    </Title>
  );
});

export default TitleComponent;
