const MODULES = {
  home: 'home',
  inbox: 'inbox',
  skills: 'ai-skills',
  inventory: 'inventory',
  channels: 'channels',
  customers: 'customers',
  spaceSettings: 'settings',
  AIChannels: 'AIChannels',
  admin: 'admin',
  help: 'help',

  skillsPersonality: 'skills/personality',
  skillsKnowledge: 'skills/knowledge',
  skillsHousekeeping: 'skills/housekeeping',
  skillsRecommendations: 'skills/recommendations',
  skillsPMS: 'skills/pms',
  skillsLeadGeneration: 'skills/leadGeneration',
  skillsConnectToHuman: 'skills/connectToHuman',
  skillsConfig: 'skills/config',

  channelWebsite: 'channels/website',
  channelWhatsApp: 'channels/whatsapp',
  channelSms: 'channels/sms',
  channelEmail: 'channels/email',
  channelFacebook: 'channels/facebook',
  channelInstagram: 'channels/instagram',
  channelBookingCom: 'channels/booking-com',
  channelFreshchat: 'channels/freshchat',
  channelSlack: 'channels/slack',

  inventoryStays: 'inventory/stays',
  inventoryExperience: 'inventory/experience',
  inventoryCalendar: 'inventory/calendar',
  inventoryBookingEngine: 'inventory/bookingEngine',
  inventoryPayouts: 'inventory/payouts',

  settingsTeam: 'settings/team',
  settingsIntegrations: 'settings/integrations',
  settingsAdmin: 'settings/admin',
};

// '*' represents super admin permission
const MODULE_PERMISSIONS = {
  [MODULES.home]: ['*', 'CHANGE_SPACE_DETAILS', 'HOMEPAGE::ALL'],
  [MODULES.inbox]: ['*', 'INBOX::CHANNEL:ALL', 'INBOX::CHANNEL:WHATSAPP'],
  [MODULES.skills]: ['*', 'CHANGE_SPACE_DETAILS'],
  [MODULES.inventory]: ['*', 'CHANGE_SPACE_DETAILS'],
  [MODULES.channels]: ['*', 'CHANGE_SPACE_DETAILS'],
  [MODULES.customers]: ['*', 'CHANGE_SPACE_DETAILS'],
  [MODULES.spaceSettings]: ['*', 'CHANGE_SPACE_DETAILS'],
  [MODULES.AIChannels]: ['*', 'CHANGE_SPACE_DETAILS'],
  [MODULES.admin]: ['*'],
  [MODULES.help]: ['*'],

  [MODULES.channelWebsite]: ['*', 'CHANGE_SPACE_DETAILS'],
  [MODULES.channelWhatsApp]: ['*', 'CHANGE_SPACE_DETAILS'],
  [MODULES.channelSms]: ['*', 'CHANGE_SPACE_DETAILS'],
  [MODULES.channelEmail]: ['*', 'CHANGE_SPACE_DETAILS'],
  [MODULES.channelFacebook]: ['*', 'CHANGE_SPACE_DETAILS'],
  [MODULES.channelInstagram]: ['*', 'CHANGE_SPACE_DETAILS'],
  [MODULES.channelBookingCom]: ['*', 'CHANGE_SPACE_DETAILS'],
  [MODULES.channelFreshchat]: ['*', 'CHANGE_SPACE_DETAILS'],
  [MODULES.channelSlack]: ['*', 'CHANGE_SPACE_DETAILS'],

  [MODULES.skillsPersonality]: ['*', 'CHANGE_SPACE_DETAILS'],
  [MODULES.skillsKnowledge]: ['*', 'CHANGE_SPACE_DETAILS'],
  [MODULES.skillsHousekeeping]: ['*', 'CHANGE_SPACE_DETAILS'],
  [MODULES.skillsRecommendations]: ['*', 'CHANGE_SPACE_DETAILS'],
  [MODULES.skillsPMS]: ['*', 'CHANGE_SPACE_DETAILS'],
  [MODULES.skillsLeadGeneration]: ['*', 'CHANGE_SPACE_DETAILS'],
  [MODULES.skillsConnectToHuman]: ['*', 'CHANGE_SPACE_DETAILS'],
  [MODULES.skillsConfig]: ['*'],

  [MODULES.inventoryStays]: ['*', 'CHANGE_SPACE_DETAILS'],
  [MODULES.inventoryExperience]: ['*', 'CHANGE_SPACE_DETAILS'],
  [MODULES.inventoryCalendar]: ['*', 'CHANGE_SPACE_DETAILS'],
  [MODULES.inventoryBookingEngine]: ['*', 'CHANGE_SPACE_DETAILS'],
  [MODULES.inventoryPayouts]: ['*', 'CHANGE_SPACE_DETAILS'],

  [MODULES.settingsTeam]: ['*', 'CHANGE_SPACE_DETAILS'],
  [MODULES.settingsIntegrations]: ['*', 'CHANGE_SPACE_DETAILS'],
  [MODULES.settingsAdmin]: ['*', 'CHANGE_SPACE_DETAILS'],
};

// eslint-disable-next-line import/prefer-default-export
export { MODULE_PERMISSIONS, MODULES };
