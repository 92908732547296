import http from './axiosConfig';
import APIError from './customErrors';

// eslint-disable-next-line import/prefer-default-export
export const globalSearch = async (payload) => {
  try {
    const response = await http.post(`/search`, payload, {
      withCredentials: true,
    });
    return response.data;
  } catch (error) {
    throw new APIError(error);
  }
};
