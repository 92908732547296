import { Global } from '@mantine/core';

export default function PlatformGlobalStyles() {
  return (
    <Global
      styles={(theme) => ({
        body: {
          backgroundColor: theme.colors.platformBodyColor,
        },
      })}
    />
  );
}
