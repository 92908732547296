import { createStyles } from '@mantine/core';
import { Title, Button } from '../../atomic';

const useStyles = createStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: 141,
    padding: '0 4rem 0 4rem',
    borderBottom: '1px solid #000000',
    '&.noBorder': {
      borderBottom: 'unset',
      height: 140,
    },
    [theme.fn.smallerThan('sm')]: {
      padding: 'unset',
    },
  },
}));

export default function SectionHeader({
  title,
  titleProps,
  buttonText,
  buttonProps,
  showButton,
  noBorder = false,
}) {
  const { classes, cx } = useStyles();
  return (
    <div className={cx(classes.root, { noBorder })}>
      <Title size="2.2rem" weight={500} {...titleProps}>
        {title}
      </Title>
      {showButton && (
        <Button variant="outline" color="dark" {...buttonProps}>
          {buttonText}
        </Button>
      )}
    </div>
  );
}
