import { Drawer, createStyles } from '@mantine/core';

const useStyles = createStyles(() => ({
  root: {
    height: '100vh',
    overflowY: 'auto',
  },
}));

export default function DrawerComponent(props) {
  const { classes, cx } = useStyles();
  return <Drawer {...props} className={cx(classes.root, props.className)} />;
}
