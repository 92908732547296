import { Text, createStyles } from '@mantine/core';
import ExclamationIcon from '@akin/ui-lib/svg/ExclamationIcon';

const useStyles = createStyles(() => ({
  root: {},
}));

export default function DeliveryFailureStatus({ className }) {
  const { cx, classes } = useStyles();
  return (
    <div className={cx(classes.root, className)}>
      <ExclamationIcon fill="#c83c3c" height={14} width={14} />
    </div>
  );
}
