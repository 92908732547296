export default function WhatsappTick({ isDoubleTick = false, ...props }) {
  return (
    <svg
      viewBox="0 0 75 75"
      xmlns="http://www.w3.org/2000/svg"
      fill="rgb(216, 216, 216)"
      stroke="rgb(0, 0, 0)"
      strokeLinecap="round"
      strokeWidth="8px"
      strokeMiterlimit="1"
      {...props}
    >
      <g transform="matrix(1, 0, 0, 1.0000000000000002, 0.8970010154236263, -1.9045010536816633)">
        <path d="M 5.844 49.561 L 17.678 58.895" />
        <path d="M 18.226 58.425 L 50.252 18.977" />

        {isDoubleTick && (
          <>
            <path d="M 29.162 56.103 L 35.448 59.774" />
            <path d="M 35.8 59.832 L 67.362 19.991" />
          </>
        )}
      </g>
      <g transform="matrix(1, 0, 0, 1.0000000000000002, 0, 7.105427357601002e-15)" />
      <g transform="matrix(1, 0, 0, 1.0000000000000002, 0, 7.105427357601002e-15)" />
      <g transform="matrix(1, 0, 0, 1.0000000000000002, 0, 7.105427357601002e-15)" />
    </svg>
  );
}
