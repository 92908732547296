import Button from './Button';
import { Trans } from '@lingui/macro';

export default function LoadMoreButton({
  currentPage,
  totalPages,
  onLoadMore,
  loading,
}) {
  const onClickHandler = () => {
    let pageNo = currentPage;
    if (currentPage < totalPages) {
      pageNo += 1;
      onLoadMore(pageNo);
    }
  };

  if (totalPages === 0 || currentPage === totalPages) return null;

  return (
    <Button
      loading={loading}
      variant="outline"
      size="xs"
      onClick={onClickHandler}
    >
      <Trans>Load More</Trans>
    </Button>
  );
}
