/* eslint-disable no-underscore-dangle */
import { useEffect } from 'react';
import { useRouter } from 'next/router';
import NProgress from 'nprogress';
import 'nprogress/nprogress.css';

export default function TopProgressBar() {
  const router = useRouter();
  useEffect(() => {
    NProgress.configure({ showSpinner: false });

    /* 
    // SET MATOMO ANALYTICS
    // Important note: The Custom URL must be set before a Pageview tracking
    // request is sent using trackPageView
    const trackMatomoAnalytics = (url) => {
      if (window && window._paq) {
        window._paq.push([
          'setCustomUrl',
          `${process.env.NEXT_PUBLIC_APP_BASE_URL}${url}`,
        ]);
        window._paq.push(['setDocumentTitle', document.title]);
        window._paq.push(['trackPageView']);
      }
    }; */

    const handleRouteStart = (_url, { shallow }) => {
      // trackMatomoAnalytics(_url);
      if (shallow) return;
      NProgress.start();
    };
    const handleRouteDone = () => NProgress.done();

    router.events.on('routeChangeStart', handleRouteStart);
    router.events.on('routeChangeComplete', handleRouteDone);
    router.events.on('routeChangeError', handleRouteDone);

    return () => {
      router.events.off('routeChangeStart', handleRouteStart);
      router.events.off('routeChangeComplete', handleRouteDone);
      router.events.off('routeChangeError', handleRouteDone);
    };
  }, []);

  return null;
}
