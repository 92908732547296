import { Avatar, Text } from '../../atomic';
import { createStyles, useMantineColorScheme } from '@mantine/core';

const useStyles = createStyles((theme, { disableHover, textColor }) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing.xs,
    borderRadius: theme.radius.sm,
    ...(!disableHover && {
      '&:hover': {
        backgroundColor: theme.colors.gray[9],
        cursor: 'pointer',
      },
    }),
    ...(textColor && { color: 'red' }),
    '& .title, .subTitle': {
      lineHeight: '1.3',
    },
  },
}));

export default function UserButton({
  name,
  initials,
  src,
  subText,
  disableHover = false,
  className,
  textColor,
  avatarProps,
  titleProps,
  subTitleProps,
  onClick,
}) {
  const { classes, cx } = useStyles({ disableHover, textColor });
  const { colorScheme } = useMantineColorScheme();
  const isDark = colorScheme === 'dark';
  const nameColor = textColor || isDark ? 'gray.4' : 'gray.7';
  return (
    <div
      className={cx(classes.root, className)}
      onClick={onClick}
      aria-hidden="true"
    >
      <Avatar src={src} mr="sm" radius="xl" size={40} {...avatarProps}>
        {initials}
      </Avatar>
      <div>
        <Text
          size="sm"
          weight={500}
          lineClamp={1}
          transform="capitalize"
          className="title"
          color={nameColor}
          {...titleProps}
        >
          {name}
        </Text>
        {subText && (
          <Text
            size="xs"
            lineClamp={1}
            className="subTitle"
            {...subTitleProps}
            color={isDark ? 'gray.5' : 'gray.6'}
          >
            {subText}
          </Text>
        )}
      </div>
    </div>
  );
}
