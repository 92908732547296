import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  housekeepingProvider: null,
  departments: [],
};

export const housekeepingSetupSlice = createSlice({
  name: 'housekeepingSetup',
  initialState,
  reducers: {
    initializeHousekeepingSetup: (_state, action) => {
      const housekeepingSetupData = {
        ..._state,
        ...action.payload,
      };
      return housekeepingSetupData;
    },
    updateHousekeepingSetup: (_state, action) => {
      const housekeepingSetupData = {
        ..._state,
        ...action.payload,
      };
      return housekeepingSetupData;
    },
    updateHousekeepingProvider: (_state, action) => {
      const updatedHousekeepingProvider = {
        ..._state.housekeepingProvider,
        ...action.payload,
      };
      return updatedHousekeepingProvider;
    },

    resetHousekeepingSetup: () => initialState,
  },
});

export const {
  initializeHousekeepingSetup,
  updateHousekeepingSetup,
  updateHousekeepingProvider,
  resetHousekeepingSetup,
} = housekeepingSetupSlice.actions;

export const housekeepingSetupSelector = (state) => state.housekeepingSetup;

export default housekeepingSetupSlice.reducer;
