import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  open: false,
  selectedItem: {},
};

export const searchSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    openSearch: (_state) => {
      _state.open = true;
    },
    closeSearch: (_state) => {
      _state.open = false;
    },
    selectItem: (_state, action) => {
      _state.selectedItem = { ...action.payload };
    },
    resetSearch: () => initialState,
  },
});

export const { openSearch, closeSearch, selectItem, resetSearch } =
  searchSlice.actions;

export const searchSelector = (state) => state.search;

export default searchSlice.reducer;
