import { Radio, createStyles } from '@mantine/core';

const useStyles = createStyles(() => ({
  root: {
    display: 'flex',
  },
}));

export default function RadioComponent({ className, ...props }) {
  const { classes, cx } = useStyles();
  return <Radio className={cx(classes.root, className)} {...props} />;
}

RadioComponent.Group = Radio.Group;
