import { Text } from '@akin/ui-lib';
import { createStyles } from '@mantine/core';

const useStyles = createStyles((theme) => ({
  root: {
    overflowY: 'auto',
    // ...theme.other.hideScrollBar,
    ...theme.other.customScrollbar,
    padding: 10,
    backgroundColor: '#F6FAFB',
    marginBottom: 10,
  },
  container: {
    maxWidth: 'calc(100% + 150px)',
    display: 'flex',
    gap: 2,
    alignItems: 'center',
    borderRadius: 8,
    visibility: 'visible',
    [theme.fn.smallerThan('xs')]: {
      '&.isCollapsed': {
        visibility: 'hidden',
      },
    },
  },
  tab: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    gap: 5,
    borderRadius: 32,
    padding: '5px 10px',
    backgroundColor: '#ddd',
    '&.active': {
      backgroundColor: '#000000',
      color: '#FFFFFF',
    },
    '&.active > *': {
      fontWeight: 600,
    },
  },
  tabValue: {
    backgroundColor: '#FFFFFF',
    minWidth: 40,
    height: 20,
    borderRadius: 32,
    textAlign: 'center',
    padding: '0 5px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&.active': {
      fontWeight: 600,
      color: '#000000',
    },
  },
}));

function ConversationTab({ tab, onClick, active }) {
  const { classes, cx } = useStyles();
  return (
    <div
      key={tab.key}
      className={cx(classes.tab, { active })}
      onClick={onClick}
      aria-hidden
    >
      <Text size="xs">{tab.label}</Text>
      <div className={cx(classes.tabValue, { active })}>
        <Text size="xs">{tab.value}</Text>
      </div>
    </div>
  );
}

function ConversationTabs(props) {
  const { children, isCollapsed } = props;
  const { classes, cx } = useStyles();
  return (
    <div className={classes.root}>
      <div className={cx(classes.container, { isCollapsed })}>{children}</div>
    </div>
  );
}

ConversationTabs.Content = ConversationTab;

export default ConversationTabs;
