import { Avatar, createStyles } from '@mantine/core';

const useStyles = createStyles((theme) => ({
  root: {
    backgroundColor: theme.colors.gray[6],
  },
}));

export default function AvatarComponent(props) {
  const { classes, cx } = useStyles();
  return (
    <Avatar {...props} className={cx(classes.root, props.className)}>
      {props.children}
    </Avatar>
  );
}

AvatarComponent.Group = Avatar.Group;
