import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

export const spacesSlice = createSlice({
  name: 'spaces',
  initialState,
  reducers: {
    initSpaces: (_state, action) => {
      const spaces = action.payload;
      const normalizedSpaces = spaces.reduce((prev, curr) => {
        // eslint-disable-next-line no-param-reassign
        prev[curr.space_id] = { ...curr };
        return prev;
      }, {});
      return normalizedSpaces;
    },
    addNewSpace: (_state, action) => {
      const spaceId = action.payload.space_id;
      _state[spaceId] = action.payload;
    },
    updateSpace: (_state, action) => {
      const spaceId = action.payload.space_id;
      _state[spaceId] = action.payload;
    },
    removeSpace: (_state, action) => {
      const { spaceId } = action.payload;
      delete _state[spaceId];
    },
    resetSpaces: () => initialState,
  },
});

export const {
  initSpaces,
  addNewSpace,
  updateSpace,
  removeSpace,
  resetSpaces,
} = spacesSlice.actions;

export const spacesSelector = (state) => state.spaces;

export const spaceSelector = (spaceId) => (state) =>
  state?.spaces?.[spaceId] || {};

export default spacesSlice.reducer;
