// eslint-disable-next-line import/prefer-default-export
export function normalizeArray(data, id) {
  if (!id) {
    // eslint-disable-next-line no-console
    console.error('Please provide a normalizing id');
    return {};
  }

  try {
    const normalized = data.reduce((prev, curr) => {
      // eslint-disable-next-line no-param-reassign
      prev[curr?.[id]] = { ...curr };
      return prev;
    }, {});

    return normalized;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }
}
