import axios from 'axios';
import http from './axiosConfig';
import APIError from './customErrors';
// import PLATFORM_CONTENT from '../constants/data/platformContent';

export const getPlatformContent = async () => {
  try {
    const response = await http.get(`/content/platform`, {
      withCredentials: true,
    });
    return response.data;
    // return { data: PLATFORM_CONTENT };
  } catch (error) {
    throw new APIError(error);
  }
};

export const getSpaceProfile = async (id) => {
  try {
    const response = await http.get(`/space/spaceProfile?space_id=${id}`, {
      withCredentials: true,
    });
    return response.data;
  } catch (error) {
    throw new APIError(error);
  }
};

export const updateSpaceProfile = async (id, data) => {
  try {
    const response = await http.patch(
      `/space/spaceProfile?space_id=${id}`,
      data,
      {
        withCredentials: true,
      }
    );
    return response.data;
  } catch (error) {
    throw new APIError(error);
  }
};

export const createSpace = async (data) => {
  try {
    const response = await http.post(`/space/`, data, {
      withCredentials: true,
    });
    return response.data;
  } catch (error) {
    throw new APIError(error);
  }
};

export const getSpaceById = async (id) => {
  try {
    const response = await http.get(`/space/${id}`, {
      withCredentials: true,
    });
    return response.data;
  } catch (error) {
    throw new APIError(error);
  }
};

export const updateSpace = async (id, data) => {
  try {
    const response = await http.patch(`/space?space_id=${id}`, data, {
      withCredentials: true,
    });
    return response.data;
  } catch (error) {
    throw new APIError(error);
  }
};

export const getPresignedURL = (data) => {
  return http.get(
    `/space/upload-cover-image?file_extension=${data.type}&space_id=${data.spaceID}`,
    {
      withCredentials: true,
    }
  );
};

export const getSpaceProfilePresignedURL = (data) => {
  return http.get(
    `/space/spaceProfile/upload-cover-image?file_extension=${data.type}&space_id=${data.spaceId}`,
    {
      withCredentials: true,
    }
  );
};

export const uploadSpaceImage = (url, data) => {
  return axios.put(url, data.file, {
    headers: {
      'Content-Type': data.type,
    },
  });
};

export const validateSpaceCode = async (code) => {
  try {
    const response = await http.get(`/space/validateCode/${code}`, {
      // params: { listing_code: code },
      withCredentials: true,
    });
    return response.data;
  } catch (error) {
    throw new APIError(error);
  }
};

export const getCalendarListings = async (params) => {
  try {
    const response = await http.get(`space/calendar`, {
      params,
      withCredentials: true,
    });
    return response.data;
  } catch (error) {
    throw new APIError(error);
  }
};

// create space category, allowed for space which is a group
export const createSpaceCategory = async (data) => {
  try {
    const response = await http.post(`/spaceCategory`, data, {
      withCredentials: true,
    });
    return response.data;
  } catch (error) {
    throw new APIError(error);
  }
};

// delete space category, allowed for space which is a group
export const deleteSpaceCategory = async (spaceCategoryId) => {
  try {
    const response = await http.delete(`/spaceCategory/${spaceCategoryId}`, {
      withCredentials: true,
    });
    return response.data;
  } catch (error) {
    throw new APIError(error);
  }
};
