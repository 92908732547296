import { Text, createStyles } from '@mantine/core';
import WhatsappTick from '../../../svg/WhatsappTick';
const useStyles = createStyles(() => ({
  root: {
    fontSize: '9px',
    color: 'gray',
    display: 'flex',
    alignItems: 'center',
    gap: '5px',
  },
}));

export default function MessageStatus({
  className,
  showTick,
  isSeen,
  showDoubleTick,
  defaultTickColor = '#b3b3b3',
  blueTickColor = '#53bdeb',
  time,
}) {
  const { classes, cx } = useStyles();
  return (
    <div className={cx(classes.root, className)}>
      {time && <Text>{time}</Text>}

      {showTick && (
        <WhatsappTick
          width={14}
          height={14}
          stroke={isSeen ? blueTickColor : defaultTickColor}
          isDoubleTick={showDoubleTick}
        />
      )}
    </div>
  );
}
