import { useDispatch, useSelector } from 'react-redux';
import {
  userInfoSelector,
  addUser as addUserAction,
  updateUser as updateUserAction,
  resetUser as resetUserAction,
} from '../features/userProfile/userSlice';

export default function useUser() {
  const dispatch = useDispatch();
  const user = useSelector(userInfoSelector);

  const addUser = (data) => {
    const { spaces, ...userInfo } = data;
    // user.permissions = { null: ['*'] }
    // when spaceId "null" exists in the permissions object with value ['*'] in user reducer,
    // the user is considered to be a "Super admin"
    const isSuperAdmin = userInfo?.permissions?.null?.includes('*') || false;
    dispatch(addUserAction({ ...userInfo, isSuperAdmin }));
  };

  const updateUser = (data) => {
    const updatedInfo = { ...user, ...data };
    dispatch(updateUserAction({ ...updatedInfo }));
  };

  const resetUser = () => {
    dispatch(resetUserAction());
  };

  return {
    user,
    addUser,
    updateUser,
    resetUser,
  };
}
