import Sidebar from './Sidebar';
import Footer from './Footer';
import MainContent from './MainContent';
import SidebarWrapper from './SidebarWrapper';

export default {
  Sidebar,
  Footer,
  MainContent,
  SidebarWrapper,
};
