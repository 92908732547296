import { Modal, createStyles } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';

const useStyles = createStyles((theme) => ({
  root: {
    '& .mantine-Modal-modal': {
      padding: 'unset',
      overflow: 'hidden',
    },
    '& .mantine-Modal-header': {
      padding: theme.spacing.sm,
      paddingLeft: theme.spacing.lg,
      paddingRight: theme.spacing.lg,
      margin: 'unset',
      borderBottom: '1px solid',
      borderBottomColor: theme.colors.gray[2],
    },
    '& .mantine-Modal-body': {
      padding: theme.spacing.sm,
      paddingLeft: theme.spacing.lg,
      paddingRight: theme.spacing.lg,
    },
  },
}));

export default function ModalComponent({
  disableMobileFullScreen = false,
  className,
  ...props
}) {
  const { classes, cx } = useStyles();
  const isMobileScreen = useMediaQuery('(max-width: 780px)');
  const fullScreen = disableMobileFullScreen ? false : isMobileScreen;

  return (
    <Modal
      className={cx(classes.root, className)}
      fullScreen={fullScreen}
      {...props}
    >
      {props.children}
    </Modal>
  );
}
