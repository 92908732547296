import { Global } from '@mantine/core';

export default function PublicGlobalStyles() {
  return (
    <Global
      styles={(theme) => ({
        body: {
          backgroundColor:
            theme.colorScheme === 'dark'
              ? theme.black
              : theme.colors.publicBodyColor,
        },
      })}
    />
  );
}
