const PAGE_ROUTES = {
  login: '/login',
  signup: '/signup',
  passwordReset: '/password-reset',
  passwordResetChange: '/password-reset/change',
  verifyEmail: '/verify-email',
  acceptInvite: '/invite/accept',

  home: '/',
  homeSpaceCode: '/[spaceCode]',
  settings: '/settings',
  settingsSpace: '/settings/space',

  listingCreate: '/[spaceCode]/listings/create',
  listingUpdate: '/[spaceCode]/listings/[listingId]',
  listingTab: '/[spaceCode]/listings/tabs/[tabId]',

  profile: '/profile',

  payouts: '/[spaceCode]/payouts/[tabId]',
  stripeAuthReturn: '/payments/stripe/return',

  calendar: '/[spaceCode]/calendar',
  eventView: '/[spaceCode]/event/[listingCode]',
  bookingView: '/[spaceCode]/booking/[bookingCode]',

  collaborations: '/[spaceCode]/collaborations',
  collaborationId: '/[spaceCode]/collaborations/[collaborationId]',
  collaborationInvite: '/collaboration-invite',

  reports: '/[spaceCode]/reports',
  bookingEngine: '/[spaceCode]/booking-engine',

  help: '/help',

  inbox: '/inbox',
  inventory: '/inventory/',
  inventoryStays: '/inventory/stays',
  pmsRoomTypeSetup: '/inventory/stays/setup',
  inventoryExperiences: '/inventory/experiences',
  inventoryCalendar: '/inventory/calendar',
  inventoryBookingEngine: '/inventory/booking-engine',
  inventoryPayouts: '/inventory/payouts/[tabId]',
  customers: '/customers',

  admin: '/admin',

  aiSkill: '/ai-agent-skills/',
  aiSkillPersonality: '/ai-agent-skills/personality',
  aiSkillHousekeeping: '/ai-agent-skills/housekeeping',
  aiSkillKnowledge: '/ai-agent-skills/knowledge',
  aiSkillRecommendations: '/ai-agent-skills/recommendations',

  aiSkillPMS: '/ai-agent-skills/pms',
  aiSkillLeadGen: '/ai-agent-skills/lead-generation',
  aiSkillConnectToHuman: '/ai-agent-skills/connect-to-human',
  aiSkillConfig: '/ai-agent-skills/config',
  housekeepingSetup: '/housekeeping/setup',

  channels: '/channels',
  channelWebsite: '/channels/website',
  channelWhatsApp: '/channels/whatsapp',
  channelSms: '/channels/sms',
  channelEmail: '/channels/email',
  channelFacebook: '/channels/facebook',
  channelInstagram: '/channels/instagram',
  channelBookingCom: '/channels/booking-com',
  channelFreshchat: '/channels/freshchat',
  channelSlack: '/channels/slack',

  settingsBasic: '/settings',
  settingsTeam: '/settings/team',
  settingsIntegrations: '/settings/integrations/[tabId]',
  settingsIntegrationsPms: '/settings/integrations/pms',
  settingsIntegrationsHousekeeping: '/settings/integrations/housekeeping',
  settingsAdmin: '/settings/admin',

  terms: 'https://akin.chat/terms',
  privacy: 'https://akin.chat/privacy',
};

const AUTH_PAGE_ROUTES = [
  PAGE_ROUTES.login,
  PAGE_ROUTES.signup,
  PAGE_ROUTES.passwordReset,
  PAGE_ROUTES.passwordResetChange,
];

const PROTECTED_PAGE_ROUTES = Object.keys(PAGE_ROUTES).reduce((arr, pathID) => {
  const currentPath = PAGE_ROUTES[pathID];
  if (AUTH_PAGE_ROUTES.includes(currentPath)) return arr;
  arr.push(currentPath);
  return arr;
}, []);

const PUBLIC_PAGES = [
  PAGE_ROUTES.verifyEmail,
  PAGE_ROUTES.acceptInvite,
  PAGE_ROUTES.collaborationInvite,
];

const INVITE_PAGES = [
  PAGE_ROUTES.acceptInvite,
  PAGE_ROUTES.collaborationInvite,
];

export default PAGE_ROUTES;
export { AUTH_PAGE_ROUTES, PROTECTED_PAGE_ROUTES, PUBLIC_PAGES, INVITE_PAGES };
