import React from 'react';
import { Tooltip, createStyles } from '@mantine/core';
import { Text } from '@akin/ui-lib';

const useStyles = createStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    padding: '15px 10px',
    cursor: 'pointer',
    justifyContent: 'flex-start',
    borderRadius: 6,
    '&.active': {
      backgroundColor: '#F6FAFB',
    },
  },
  content: {
    overflow: 'hidden',
    flexGrow: 1,
  },
  tag: {
    display: 'flex',
    alignItems: 'center',
    borderRadius: 5,
    padding: '2px 8px',
    color: '#fff',

    '& .label': {
      fontSize: 9,
      letterSpacing: 0.5,
      fontWeight: 500,
    },
    [theme.fn.smallerThan('md')]: {
      display: 'none',
    },
  },
  time: {
    // [theme.fn.smallerThan('sm')]: {
    //   display: 'none',
    // },
  },
  children: {
    [theme.fn.smallerThan('sm')]: {
      display: 'none',
    },
  },
  info: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontSize: 14,
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  titleTag: {
    display: 'flex',
    alignItems: 'center',
    width: '70%',
  },
  infoContent: {},
  messageCount: {
    backgroundColor: '#636ecb',
    color: theme.white,
    borderRadius: '50%',
    minWidth: 20,
    height: 20,
    textAlign: 'center',
    fontSize: 12,
    fontWeight: 600,
  },
  name: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}));

export default function Conversation({
  name,
  info,
  channel,
  channelColorStyle,
  className,
  active,
  dateTime,
  timeAgoDateTime,
  onClick,
  messageCount,
  showMessageCountNotification = false,
  children,
}) {
  const { classes, cx } = useStyles();

  return (
    <div
      className={cx(classes.container, { active }, className)}
      onClick={onClick}
    >
      <div className={classes.children}>{children}</div>

      {(name || info) && (
        <div className={cx(classes.content, className)}>
          <div className={classes.header}>
            <div className={classes.titleTag}>
              <Text weight={700} mr={6} size="sm" className={classes.name}>
                {name}
              </Text>
              <div className={cx(classes.tag)} style={channelColorStyle}>
                {/* <div className={classes.dot} style={channelColorStyle} /> */}
                <Text className="label">{channel}</Text>
              </div>
            </div>

            <div className={classes.time}>
              <Tooltip label={dateTime}>
                <Text size={10} color="dimmed" ta="right">
                  {timeAgoDateTime}
                </Text>
              </Tooltip>
            </div>
          </div>

          <div className={classes.info}>
            {!!info && (
              <Text
                size="xs"
                weight={500}
                className={classes.infoContent}
                color={showMessageCountNotification > 0 ? 'black' : 'dimmed'}
                lineClamp={1}
              >
                {info}
              </Text>
            )}

            {showMessageCountNotification > 0 && (
              <div className={classes.messageCount}>{messageCount || ''}</div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}
