import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import PAGE_ROUTES, {
  PROTECTED_PAGE_ROUTES,
  PUBLIC_PAGES,
} from '../constants/pageRoutes';
import { initSpaces } from '../features/spaces/spacesSlice';
import { logout as logoutAPI, getUserInfo } from '../services/auth';
import useSpace from './useSpace';
// import useSpaceSettings from './useSpaceSettings';
import useURLParams from './useURLParams';
import useUser from './useUser';

export default function useAuth() {
  const { router } = useURLParams();
  const dispatch = useDispatch();
  const user = useUser();
  const { resetSpaces } = useSpace();
  // const { switchToSpace } = useSpaceSettings();

  const login = (payload) => {
    const { spaces, ...userData } = payload;
    user.addUser(userData);
    dispatch(initSpaces(spaces));

    // if (!currentSpaceId && spaces.length > 0) {
    //   const selectedSpaceId = payload?.invitedSpaceId || spaces[0].space_id;
    //   switchToSpace(selectedSpaceId);
    // }
  };

  const logout = useCallback(async () => {
    await logoutAPI();
    user.resetUser();
    resetSpaces();
    router.replace(PAGE_ROUTES.login);
  }, []);

  const checkAuthStatus = async () => {
    if (PUBLIC_PAGES.includes(router.route)) return;
    if (PROTECTED_PAGE_ROUTES.includes(router.route)) {
      try {
        const response = await getUserInfo();
        user.updateUser(response?.data);
      } catch (error) {
        if (error.status === 401) {
          logout();
        }
      }
    }
  };

  return { login, logout, checkAuthStatus };
}
