import Text from '../text';
import { createStyles } from '@mantine/core';

const useStyles = createStyles((theme) => ({
  root: {
    marginBottom: theme.spacing.xl,
  },
  labelContainer: {
    display: 'block',
    width: 'fit-content',
    cursor: 'pointer',
    '& input[type=checkbox]': {
      verticalAlign: 'middle',
      position: 'relative',
      bottom: '1px',
      cursor: 'pointer',
    },
  },
}));

export const LABEL_PLACEMENT = {
  left: 'left',
  right: 'right',
};

export default function Checkbox({
  label = '',
  labelSize = 'sm',
  labelPlacement = LABEL_PLACEMENT.right,
  error = '',
  name = '',
  className,
  ...inputProps
}) {
  const { classes, cx } = useStyles();

  return (
    <div className={cx(classes.root, className)}>
      <label htmlFor={name} className={classes.labelContainer}>
        {labelPlacement === LABEL_PLACEMENT.left && (
          <Text
            component="span"
            size={labelSize}
            mr={10}
            className={classes.label}
          >
            {label}
          </Text>
        )}

        <input type="checkbox" name={name} {...inputProps} />

        {labelPlacement === LABEL_PLACEMENT.right && (
          <Text component="span" size={labelSize} ml={10}>
            {label}
          </Text>
        )}
      </label>

      {error && (
        <Text color="red" size="xs">
          {error}
        </Text>
      )}
    </div>
  );
}
