import { Text } from '@akin/ui-lib';
import { createStyles } from '@mantine/core';

const useStyles = createStyles(() => ({
  container: {
    width: '90%',
    margin: '20px auto',
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    '&:before, &:after': {
      backgroundColor: 'none',
      content: '""',
      flexGrow: 1,
      height: 1,
    },
    '&.showSeparatorLine:before, &.showSeparatorLine:after': {
      backgroundColor: '#eae9ea',
    },
  },
  date: {
    position: 'relative',
    zIndex: 1,
    margin: '0 10px',
    borderRadius: 25,
    '&.showPill': {
      backgroundColor: '#eae9ea',
      padding: '5px 10px',
    },
  },
}));

export default function MessageSeparator({
  content,
  showSeparatorLine = true,
  showPill = true,
  textProps = { color: 'dimmed' },
}) {
  const { classes, cx } = useStyles();
  return (
    <div className={cx(classes.container, { showSeparatorLine })}>
      <div className={cx(classes.date, { showPill })}>
        <Text size="xs" {...textProps}>
          {content}
        </Text>
      </div>
    </div>
  );
}
