import { createStyles } from '@mantine/core';
import { LoadingOverlay } from '@akin/ui-lib';

const useStyles = createStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    '&.hideContainer': {
      display: 'none',
      [theme.fn.largerThan('xs')]: {
        display: 'flex',
      },
    },
    [theme.fn.smallerThan('xs')]: {
      width: 'calc(100% - 20px)',
    },
  },
}));

export default function ChatContainer({ children, isLoading, hideContainer }) {
  const { cx, classes } = useStyles();
  return (
    <div className={cx(classes.root, { hideContainer })}>
      {children}
      <LoadingOverlay visible={isLoading} overlayOpacity={0.1} />
    </div>
  );
}
