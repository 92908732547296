import { Image, createStyles } from '@mantine/core';

const ARROW_RIGHT =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAABmJLR0QA/wD/AP+gvaeTAAADGElEQVRYhe2WTUsbURiFT2buYCbgMJJ0Mn5UyUxlWsQBAwkUCaGtqc2muhUXFYofSBcK0n+gCSHgwr+grt1qoQVXGl11IRWkWI1Wq8UUzEiJ8e1GS5vEOImFbjwwm8udc5577zt3XuBOd/rPclT5nocx1qZpWhvP847d3d2N09PTjwC+/0u4Qjl8Pt9rTdM+cxx3AYD+fDiOuzAM40swGHyDChZma6IkSQ8kSXqfTqfvq6qKnp4ehMNhNDY2AgDS6TSWl5exsLCAw8ND6Lp+4PF4nq2urm5Uvs4CtbS0PJUk6afL5aJEIkFnZ2d0nSzLong8TqIokizLuUgk8vJW4TU1NXptbe3P+vp6Wl9fvza4UKlUilRVpbq6uvOurq72avMdTU1NOy6Xq2T41tbWjRCiKJJhGN9QTbH7fL7XACiRSBSZLy0tkSAIlEwmy0LEYjECQNFo9G3FAJqmfVZVteSZZzIZCgaDBKAshGVZpCgKtbe3H1Sa7+E47mJkZORa85OTE1sQQ0NDxBijUChUbzudMfYEAM3Pz5fdYjsQs7OzBID6+vpelcriSg3quv4QABoaGsqCyrKMxcVFBAIBTExMYHp6umjO1V2Rz+cN2wCMsZLjtxHP87xtgO3t7U8AsL+/X9Y0k8mgu7sba2trSCaTGB8fL5qzt7cHABAEYbMSYDfP8xfDw8O3LsLBwUFijFF/f39TJQAwDOOL1+sly7KKTO1+htlslhRFoY6OjqOKwgHg8q9G8Xi8yNjuRTQ5OUkAaGBgYLJiAAAOXde/iqJIqVSqyPymq3hlZYWcTieZpplBtX2H3+9/JMtyTlXVkhDlwr1eL7nd7vzY2NjjqsKvFIlEXsqynBNFkWKxWMmauFI2m6WpqSlyOp3kdrvzo6OjAzf529qazs7OtuPj4w+bm5v3FEVBb28vQqEQmpubAQA7Ozu/G5KjoyOYpvkjHA6/mJmZWbnV6gtho9HoW9M0D3ie/6sdA0CMMfL7/ceXBfdvW7JCBQIBtbW19Xkul3skCAITBGHj/Pz83dzcXLoavzvd6b/qF3x1ZSo4HKnRAAAAAElFTkSuQmCC';

const useStyles = createStyles((theme, { isCollapsed }) => ({
  root: {
    width: '25%',
    height: 'inherit',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    marginLeft: 10,

    ...theme.other.sidebarAnimation,

    [theme.fn.smallerThan('xs')]: {
      position: 'absolute',
      background: 'white',
      width: 'calc(100% - 24px)',
      zIndex: 4,

      '&.isCollapsed': {
        width: '3%',
        marginLeft: 0,
      },
    },
  },
  expand: {
    bottom: '0px',
    left: '100%',
    position: 'absolute',
    top: '0px',
    // width: '24px',
    [theme.fn.largerThan('xs')]: {
      display: 'none',
    },
  },
  imageContainer: {
    position: 'absolute',
    cursor: 'pointer',
    top: '57px',
    left: '-16px',
    zIndex: 3,
  },
  arrowIcon: {
    transform: isCollapsed ? 'rotate(0deg)' : 'rotate(180deg)',
  },
}));

export default function Sidebar({
  children,
  className,
  isCollapsed,
  toggleCollapse,
}) {
  const { classes, cx } = useStyles({ isCollapsed });

  return (
    <div className={cx(classes.root, className, { isCollapsed })}>
      <div className={classes.expand}>
        <div className={classes.imageContainer}>
          <Image
            src={ARROW_RIGHT}
            className={classes.arrowIcon}
            onClick={toggleCollapse}
            width={30}
            height={30}
          />
        </div>
      </div>
      {children}
    </div>
  );
}
