import { showNotification } from '@mantine/notifications';

export const showSuccess = (message, options = {}) => {
  showNotification({
    message,
    color: 'green',
    ...options,
  });
};

export const showError = (message, options = {}) => {
  showNotification({
    message,
    color: 'red',
    ...options,
  });
};

function triggerNotification({ title, body, link }) {
  try {
    if (title) {
      const notification = new Notification(title, {
        body,
      });

      if (link) {
        notification.onclick = () => {
          window.open(link, '_blank');
        };
      }
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('error trigger notification', error.message);
  }
}

export const showBrowserNotification = ({ title, body, link }) => {
  if (!('Notification' in window)) {
    // Check if the browser supports notifications
    // eslint-disable-next-line no-console
    console.error('This browser does not support desktop notification');
  } else if (Notification.permission === 'granted') {
    // Check whether notification permissions have already been granted;
    // if so, create a notification

    triggerNotification({ title, body, link });
  } else if (Notification.permission !== 'denied') {
    // We need to ask the user for permission
    Notification.requestPermission().then((permission) => {
      // If the user accepts, let's create a notification
      if (permission === 'granted') {
        triggerNotification({ title, body, link });
      }
    });
  }

  // At last, if the user has denied notifications, and you
  // want to be respectful there is no need to bother them anymore.
};
