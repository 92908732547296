import { Badge, createStyles, useMantineColorScheme } from '@mantine/core';
import { Title, Text, Button, BackgroundImage, Image } from '../../atomic';
import { Trans } from '@lingui/macro';
import ICONS from '@akin/core-lib/icons';

const useStyles = createStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
    position: 'relative',
    // marginRight: '1.5rem',
    [theme.fn.smallerThan('sm')]: {
      flexDirection: 'column',
      alignItems: 'unset',
    },
  },
  details: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  location: {
    marginRight: 10,
  },
  wrapper: {
    position: 'relative',
    pointerEvents: 'none',
    marginBottom: 10,
  },
  anchor: {
    position: 'absolute',
    inset: '0rem 0rem',
    borderRadius: theme.radius.md,
    overflow: 'hidden',
    zIndex: 0,
    '&:hover': {
      cursor: 'pointer',
      // backgroundColor: theme.colors.gray[4],
      // opacity: 0.5,
    },
  },
  dateCard: {
    display: 'flex',
    flexDirection: 'column',
    border: '2px solid',
    borderRadius: theme.radius.md,
    borderColor: theme.colors.gray[8],
    width: 50,
    // marginRight: '1.5rem',
    '& .header': {
      backgroundColor: theme.colors.gray[2],
    },
    [theme.fn.smallerThan('sm')]: {
      display: 'none',
    },
  },
  backgroundImage: {
    width: '100%',
    minWidth: 200,
    height: 320,
    borderRadius: theme.radius.md,
    overflow: 'hidden',
    [theme.fn.smallerThan('sm')]: {
      width: '100%',
      minWidth: 150,
      marginRight: 'unset',
      marginBottom: '1rem',
      height: 240,
    },
  },
  actions: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    [theme.fn.smallerThan('sm')]: {
      marginLeft: 'unset',
      marginTop: '1rem',
      width: '100%',
    },
  },
  icon: {
    cursor: 'pointer',
    marginLeft: 20,
  },
  listingGroups: {
    marginBottom: 10,
  },
}));

export default function ListingCard({
  enableActions,
  imgSrc,
  LinkComponent,
  linkSrc,
  onClickManage,
  title,
  subText,
  city,
  country,
  duration,
  online,
  listingGroups = [],
  buttonText = 'Manage',
  onlineText = 'Video call',
  durationText,
}) {
  const { classes, cx } = useStyles();
  const { colorScheme, toggleColorScheme } = useMantineColorScheme();
  const isDark = colorScheme === 'dark';

  const durationDesc =
    durationText || duration
      ? duration < 1
        ? `All day`
        : `${duration} mins`
      : null;

  return (
    <div className={classes.root}>
      {!enableActions && (
        <LinkComponent href={linkSrc}>
          <a className={classes.anchor} />
        </LinkComponent>
      )}

      <BackgroundImage
        imgSrc={imgSrc}
        className={cx(classes.wrapper, classes.backgroundImage)}
      />

      <div className={classes.details}>
        {!online && (
          <Text size="xs" color="dimmed" className={classes.location}>
            {city ? city : ''}
            {city ? ', ' : ''}
            {country ? country : ''}
          </Text>
        )}
        {online && (
          <Text size="xs" color="dimmed" className={classes.location}>
            {onlineText}
          </Text>
        )}
        <Text size="xs" color="dimmed">
          {durationDesc}
        </Text>
      </div>

      <div className={cx(classes.wrapper, classes.info)}>
        <Title order={5} mb={2} color={isDark ? 'gray.3' : 'gray.7'}>
          {title}
        </Title>
        <Text size="sm" color="dimmed">
          {subText}
        </Text>
      </div>

      {enableActions && (
        <>
          {listingGroups.length > 0 && (
            <div className={classes.listingGroups}>
              {listingGroups.map((item) => (
                <Badge
                  variant="outline"
                  color="gray.6"
                  key={item.listing_group_id}
                  sx={{ marginRight: 5 }}
                >
                  {item.name}
                </Badge>
              ))}
            </div>
          )}
          <div className={classes.actions}>
            <Button size="xs" variant="default" onClick={onClickManage}>
              {buttonText}
            </Button>
            {/* <LinkComponent href="/" passhref> */}
            <a target="_blank" rel="noopener noreferrer" href={linkSrc}>
              <Image
                src={ICONS.newTab}
                height={15}
                width={15}
                className={classes.icon}
              />
            </a>
            {/* </LinkComponent> */}
          </div>
        </>
      )}
    </div>
  );
}
