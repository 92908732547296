import { Popover } from '@mantine/core';

function PopoverTarget(props) {
  return <Popover.Target {...props}>{props.children}</Popover.Target>;
}

function PopoverDropDown(props) {
  return <Popover.Dropdown {...props}>{props.children}</Popover.Dropdown>;
}

export default function PopoverComponent(props) {
  return <Popover {...props}>{props.children}</Popover>;
}

PopoverComponent.Target = PopoverTarget;
PopoverComponent.Dropdown = PopoverDropDown;
