import { createStyles } from '@mantine/core';

const useStyles = createStyles((theme) => ({
  root: {
    flex: 1,
    overflowY: 'auto',
    ...theme.other.hideScrollBar,
  },
}));

export default function ConversationList({ children }) {
  const { classes } = useStyles();
  return <div className={classes.root}>{children}</div>;
}
