import { createStyles } from '@mantine/core';
import ReactDOM from 'react-dom';

const useStyles = createStyles((theme) => ({
  root: {
    position: 'fixed',
    inset: '0px',
    zIndex: 9999,
    backdropFilter: 'blur(3px)',
  },
  inner: {
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    paddingTop: 120,
  },
  searchInput: {
    zIndex: 2,
    width: '100%',
    maxWidth: '600px',
  },
  overlay: {
    zIndex: 1,
    position: 'fixed',
    inset: '0px',
    backgroundColor: theme.black,
    opacity: 0.25,
  },
}));

export default function Spotlight({
  rootId = 'spotlight-root',
  children,
  onOverlayClick,
}) {
  const { classes } = useStyles();
  const spotlightRoot =
    typeof document !== 'undefined' ? document.getElementById(rootId) : null;

  return ReactDOM.createPortal(
    <div className={classes.root}>
      <div className={classes.inner}>
        <div className={classes.searchInput}>{children}</div>
      </div>
      <div className={classes.overlay} onClick={onOverlayClick}></div>
    </div>,
    spotlightRoot
  );
}
