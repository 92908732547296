import { LoadingOverlay } from '@mantine/core';
import Loader from '../../atomic/loader';

export default function LoadingOverlayComponent({ loaderProps, ...props }) {
  return (
    <LoadingOverlay loader={<Loader {...loaderProps} />} {...props}>
      {props.children}
    </LoadingOverlay>
  );
}
